{
  "tool": [
    {
      "number": 1,
      "id": "vscode",
      "name": "VSCode",
      "workmanship": 4,
      "summary": "Dreamweaver, Atom 등을 사용해왔고 최근에는 VSCode에 정착하여 Sublime Text 등을 보조로 사용하고 있습니다."
    },
    {
      "number": 2,
      "id": "git",
      "name": "Git",
      "workmanship": 4,
      "summary": "각 프로젝트 환경에 최대한 맞춰서 사용하며, conflict 관리도 어느 정도 할 수 있습니다."
    },
    {
      "number": 3,
      "id": "bitbucket",
      "name": "Bitbucket",
      "workmanship": 4,
      "summary": "private git이 필요한 소규모 프로젝트의 경우에 쓰일 일이 많습니다.\n생성, 권한, 프로젝트 관리 등 다양하게 사용해왔습니다."
    },
    {
      "number": 4,
      "id": "sourcetree",
      "name": "Sourcetree",
      "workmanship": 3,
      "summary": "gitHub에 비해 확실히 느리다는 단점은 있지만 GUI 더 직관적이고 다양한 기능을 지원해서 오랫동안 사용하고 있습니다."
    },
    {
      "number": 5,
      "id": "jira",
      "name": "Jira",
      "workmanship": 4,
      "summary": "처음 설정 단계부터 다뤄보지는 못했지만, 사용자로서 중간 관리자로서는 자주 사용했습니다."
    },
    {
      "number": 6,
      "id": "figma",
      "name": "Figma",
      "workmanship": 4,
      "summary": "퍼블리셔로서, 개발자로서는 충분할 만큼 사용했습니다.\n잘못되거나 불편한 가이드가 보이면 담당자에게 잘 전달하는 편입니다."
    },
    {
      "number": 7,
      "id": "zeplin",
      "name": "Zeplin",
      "workmanship": 4,
      "summary": "PSD로 된 디자인을 받게 되는 경우 사용합니다."
    },
    {
      "number": 8,
      "id": "netlify",
      "name": "Netlify",
      "workmanship": 3,
      "summary": "현재 포트폴리오를 배포하며 사용했습니다.\n소규모 프로젝트나 테스트 용도로 많이 사용합니다."
    },
    {
      "number": 9,
      "id": "lighthouse",
      "name": "Lighthouse",
      "workmanship": 3,
      "summary": "사이트의 성능 개선을 위해 많이 참고하고 있습니다."
    }
  ]
}
