{
  "introduce": {
    "first": "초등학교부터 사용한 컴퓨터는 제게는 너무 신기하고 배울 것이 참 많은 기기였습니다.\n학교에서 배우는 공부보다 컴퓨터의 탐색기를 하나하나 열어보고 윈도우의 기능과 타자 연습, 다양한 게임들을 해보는 게 가장 큰 재미였습니다.\n일찍 배운 컴퓨터 타자로 당시 대학교수셨던 아버지의 $$책$$ 출간을 돕기도 했습니다.\n이후 미술 전공을 준비했었지만, 보여주기식의 반복적이고 지루한 입시 미술이 적성에 맞지 않아 고등학교를 졸업하고 바로 입대하였습니다.",
    "second": "22세에 전역을 하고는 다양한 일을 경험했습니다.\n2~30대로 이루어진 $$건설팀$$에 들어가 1년 정도 몸을 쓰는 일도 해보았고, 2년 정도 $$다양한 공장$$에서 OP(Operator) 일도 하였습니다.\n이후 학생 시절에 PC방 아르바이트 일이 즐거웠던 기억이 있어 $$프랜차이즈$$PC방에 점장으로 취업하여 2년간 일했습니다.\n하드웨어에 대한 공부도 많이 하였고, $$전국 매출 상위 1%$$의 매장이 되어 또 다른 프랜차이즈 기업에서 스카우트 제의를 받아 $$여러 매장$$을 오픈 및 관리 하였습니다.\n하지만 해당 분야에서 좋은 여건에 있었음에도 앞으로 전망이 밝지 않다는 판단에 그만두게 되었습니다.\n이후 $$웹 사이트$$를 운영하려는 지인을 돕게 되면서 웹 개발자의 영역을 알게 되었습니다.\n대부분 구글과 간단한 서적으로 독학하여 퍼블리싱을 배우고 $$지인들과 팀$$을 꾸려 외주 업무를 받아서 처리하기 시작했습니다.",
    "third": "함께 일하는 팀은 점점 전문화되어 $$회사$$가 되었고, 저는 설립 멤버로서 개발과 회사 운영을 함께 해왔습니다.\n주니어 개발자였지만 기획자가 따로 없었으므로 서비스의 개발과 개선 등을 기획부터 해왔고, 회사의 $$인사$$, $$고객 응대$$, $$세무$$, $$관리$$를 전부 맡아 처리하였습니다.\n$$적은 인원$$과 자본이지만 성장하는 실력과 회사를 보고 즐겁게 일할 수 있었습니다.\n다양한 업무 경험으로 넓은 시각이 생겼지만, 이렇게 일을 해서는 전문가가 되기는 어렵겠다는 생각에 퇴사하게 되었습니다.\n이후 프리랜서로 짧은 기간 일을 하였고, 얼마 후 결혼을 하였습니다.\n그리고 아내의 임신부터 아들의 100일까지 육아와 가사를 보조하며 개발 공부와 포트폴리오 개발을 병행했고, 현재는 프리랜서로 다양한 프로젝트에서 일하고 있습니다."
  },
  "tooltipInfo": {
    "first": [
      {
        "info": "도벽@환경도예",
        "text": "책"
      }
    ],
    "second": [
      {
        "info": "시스템 동바리 (prefabricated shoring system)",
        "text": "건설팀"
      },
      { "info": "LED, LCD, Clalen (콘택트 렌즈), PCI", "text": "다양한 공장" },
      { "info": "시즌아이(seasoni)", "text": "프랜차이즈" },
      {
        "info": "피카라이브 전국 비교 보고서 기준",
        "text": "전국 매출 상위 1%"
      },
      { "info": "오산(2), 천안, 평택", "text": "여러 매장" },
      { "info": "일반기업 솔루션 쇼핑몰", "text": "웹 사이트" },
      { "info": "본인 외 디자이너, 계약담당 등 총 3인", "text": "지인들과 팀" }
    ],
    "third": [
      { "info": "크리에이티브코드(CC)", "text": "회사" },
      { "info": "면접, 채용, 근태, 해고, 정부 혜택 처리 등", "text": "인사" },
      { "info": "계약, 기획, 기능명세, 회의, 수금 등", "text": "고객 응대" },
      { "info": "급여 명세, 연말 정산", "text": "세무" },
      { "info": "시설 및 비품 구입, PC 및 채굴기 관리 등", "text": "관리" },
      {
        "info": "프론트 2명, 백엔드 1명, 디자이너 1명, 대표",
        "text": "적은 인원"
      }
    ]
  }
}
