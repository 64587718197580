{
  "language": [
    {
      "number": 1,
      "id": "html",
      "name": "HTML",
      "workmanship": 5,
      "summary": "시멘틱 구조를 최대한 신경 써서 작업하며, 디자인 및 기획을 보고 빠르고 정확한 구조를 구현할 수 있습니다."
    },
    {
      "number": 2,
      "id": "css",
      "name": "CSS",
      "workmanship": 5,
      "summary": "대부분의 style 종류와 우선순위, 상관관계를 파악하고 있습니다.\n예전에는 'Internet Explorer 8~9'까지 크로스 브라우징 해야하는 상황도 많이 겪었습니다.\n 원하는 3D Animation을 자유롭게 작성하는 수준까지를 목표로 생각하고 있습니다."
    },
    {
      "number": 3,
      "id": "javascript",
      "name": "JavaScript",
      "workmanship": 4,
      "summary": "필요한 상황에 최적의 함수를 사용하려고 노력 중입니다. MDN이나 웹에 너무나 많은 정보가 있으므로 최근에 나온 내장함수까지도 문제없이 활용하고 있습니다."
    },
    {
      "number": 4,
      "id": "typescript",
      "name": "TypeScript",
      "workmanship": 3,
      "summary": "현재 포트폴리오에서 strict 모드까지 적용해 보았습니다.\n아직 실무에서 사용해볼 기회가 없어서 경험은 부족합니다."
    }
  ]
}
