{
  "hashtagFirst": [
    {
      "id": 1,
      "title": "# 애자일",
      "text": "'정해진 대로', '늘 하던 대로' 도 좋지만 작업 간에 알게 되는 다양한 방식과 변화를 유연하게 받아들이고 곧 잘 적용하는 편입니다.",
      "back": "AGILE"
    },
    {
      "id": 2,
      "title": "# 될 때까지",
      "text": "목표가 생기고 필요하다고 생각되면, 많은 노력과 시간이 들더라도 꿋꿋이 해내어 성취감을 얻는 걸 좋아합니다.\n처음의 생각과 다르게 아주 많이 어렵고, 말도 안 되더라도 말입니다.",
      "back": "Until it works"
    },
    {
      "id": 3,
      "title": "# 솔직한",
      "text": "당연한데 의외로 많은 사람이 못하고 있습니다.\n단점과 잘못을 포장하지 않습니다.\n양보를 영원히 할 수는 없고, 부끄러움 없이는 나아지기 어렵다고 생각합니다.",
      "back": "Honest"
    }
  ],
  "hashtagSecond": [
    {
      "id": 4,
      "title": "# 아이디어",
      "text": "항상 자신 있고 의견도 잘 내는 부분입니다.\n아주 사소하고 작은 것부터 당연하다고 여기는 것까지,\n뻔한 것을 뻔하게 하기 싫어합니다.\n남과 같이 해서는 남보다 나아질 수 없다고 생각합니다.",
      "back": "Idea"
    },
    {
      "id": 5,
      "title": "# 계획적인",
      "text": "일의 순서와 계획을 논리적으로 잘 세우고,\n갑작스러운 변수에 대한 대비도 잘 하는 편입니다.\n메사에 침착하고 효율적이려고 합니다.",
      "back": "Premeditated"
    },
    {
      "id": 6,
      "title": "# 세심한",
      "text": "정리 정돈을 잘하고, 주변 환경을 잘 인지하여 상황 파악이 빠른 편입니다.",
      "back": "Meticulous"
    }
  ]
}
