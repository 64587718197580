{
  "career": [
    {
      "id": 6,
      "keyword": "# Career",
      "title": "(주)모바일팩토리 (프리랜서)",
      "date": "2022.11 ~ 2022.4",
      "summary": ["프론트 개발"],
      "text": "(주)LF의 협력업체입니다."
    },
    {
      "id": 5,
      "keyword": "# Career",
      "title": "(주)그루트커뮤니케이션즈 (프리랜서)",
      "date": "2022.06 ~ 2023.8",
      "summary": [
        "퍼블리싱",
        "프론트 개발",
        "디자인 가이드",
        "웹 서비스 기획 참여"
      ],
      "text": "LG전자의 사내벤처입니다."
    },
    {
      "id": 4,
      "keyword": "# Career",
      "title": "(주)새롬씨앤씨 (프리랜서)",
      "date": "2022.02 ~ 2022.06",
      "summary": ["퍼블리싱 가이드", "프론트 개발"],
      "text": "포스코ICT 1차 파트너사입니다."
    },
    {
      "id": 3,
      "keyword": "# Career",
      "title": "젠나인소프트시스템 (전임)",
      "date": "2021.05 ~ 2022.02",
      "summary": ["프론트 개발"],
      "text": "SI 파견 회사입니다.\n삼성 프로젝트에서 해당 회사의 정규직 인원 충원이 꼭 필요하여 입사하였습니다."
    },
    {
      "id": 2,
      "keyword": "# Career",
      "title": "볼트러스트 (프리랜서)",
      "date": "2019.12 ~ 2020.04",
      "summary": ["퍼블리싱", "프론트 개발"],
      "text": "빗썸의 수탁서비스 자회사입니다."
    },
    {
      "id": 1,
      "keyword": "# Career",
      "title": "크리에이티브코드 (임원)",
      "date": "2016.07 ~ 2019.10",
      "summary": [
        "웹 서비스 기획",
        "UX 기획",
        "디자인 검수",
        "퍼블리싱",
        "프론트 개발",
        "회사 운영 및 인사 관리."
      ],
      "text": "지인들과 함께 웹 관련 외주작업을 받아서 일하던 중 설립된 회사입니다.\n이후 암호화폐가 가장 관심이 뜨겁던 때, 관련 프로젝트들을 전문적으로 하는 회사가 되었습니다."
    }
  ]
}
